import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/uBP8xzEvq2I">
    <SEO title="Marriage Ingredients - Marriage, Money, and Minions" />
  </Layout>
)

export default SermonPost
